<template>
    <div class="container" >
        hello
    </div>
</template>


<script>

</script>


<style lang="less" scoped>
.container {
    height: 144px;

    @media only screen and (min-width: 375px) and (max-width: 1600px) {
        height: 156.012px;

        &.errorMessage {
            height: 199.012;
        }
    }

}
</style>