<template>

    <Modal v-model="isShow" :close="closeModal">
        <div class="container">
            <div class="img-license">
                <img class="UKFCA"
                    src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/License/UK%20FCA.png"
                    alt="UKFCA">
            </div>
            <button @click="closeModal">
                close
            </button>
        </div>
    </Modal>

</template>



<script>
import { ref } from 'vue'
export default {
    data() {
        return {
            isShow: ref(true)
        }
    },
    methods: {
        showModal() {
            this.isShow = true
        },
        closeModal() {
            this.isShow = false
        }
    },
    mounted() {
    }
}
</script>
<style scoped lang="less">
.modal {
    width: 300px;
    padding: 73px 20px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 20px;
    text-align: center;
    font-size: 20px;
    border-radius: 10px;

}






.container {
    display: flex;
    justify-content: center;
    align-items: center;

    .img-license {
        position: absolute;
        bottom: 311px;

        .UKFCA {
            width: 342px;
            height: 484px;
            box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.4);

        }
    }
}
</style>