<template>
    <div>
        <BannerView />
        <SalesView />
        <GraphView />
        <QuotesView />
        <RewardsView />
        <TravelView />
        <SocialLinksKR></SocialLinksKR>
        <DisclaimerFooterKR></DisclaimerFooterKR>
        <FixedFooterKR></FixedFooterKR>
        <FooterBackgroundKR></FooterBackgroundKR>
    </div>
</template>

<script>
// @ is an alias to /src
import BannerView from '@/components/kr/BannerView.vue';
import SalesView from '../../components/kr/SalesView.vue';
import GraphView from '@/components/kr/GraphView.vue';
import TravelView from '@/components/kr/TravelView.vue';
import QuotesView from '../../components/kr/QuotesView.vue';
import RewardsView from '../../components/kr/RewardsView.vue';
import FormContactKR from "../../components/kr/FormContactKR.vue";
import FooterBackgroundKR from "../../components/kr/FooterBackgroundKR.vue";
import FixedFooterKR from "@/components/kr/FixedFooterKR.vue";
import DisclaimerFooterKR from "../../components/kr/DisclaimerFooterKR.vue";
import SocialLinksKR from "@/components/kr/SocialLinksKR.vue";


export default {
    name: 'HomeView',
    components: {
        BannerView,
        SalesView,
        GraphView,
        QuotesView,
        RewardsView,
        TravelView,
        FormContactKR,
        FooterBackgroundKR,
        FixedFooterKR,
        DisclaimerFooterKR,
        SocialLinksKR
    },

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap');




* {
    margin: 0;
    padding: 0;
}
</style>
