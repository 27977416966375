<template>
    <section class="header">
        <div class="container">
            <div class="title-txt">
                <div class="main-txt">
                    <p class="top-txt">Doo Prime と共に能力を <br> 存分に発揮してください</p>
                    <div class="bottom">
                        <p class="bottom-txt">グローバル金融センター事務室で就職ビザを発給します。
                            履歴書と関連資格証を支援部署のHRメールで送信してください。
                            条件に応じる次第連絡を取るようにします。</p>
                    </div>

                </div>
            </div>
            <div class="title-txt-mobile">
                <div class="container">
                    <div class="main-txt">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/New%20Assets/Group%2017.png"
                            alt="main-txt">
                    </div>

                </div>
            </div>

            <div class="display-img">
                <div class="row-1">

                    <img class="row-1-1"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Gallery%201.png"
                        alt="Gallery 1">
                    <img class="row-1-2"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Gallery%202.png"
                        alt="Gallery 2">
                    <img class="row-1-3"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Gallery%203.png"
                        alt="Gallery 3">

                </div>
                <div class="row-2">
                    <img class="row-2-1"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Gallery%204.png"
                        alt="Gallery 2">
                    <img class="row-2-2"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Gallery%205.png"
                        alt="Gallery 2">
                    <img class="row-2-3"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Gallery%206.png"
                        alt="Gallery 3">
                </div>
            </div>

            <div class="carousel">
                <CarouselCert class="Carousel"></CarouselCert>
            </div>


        </div>
    </section>


</template>

<script>

import CarouselCert from './Carousel/CarouselCert.vue';

export default {
    components: {
        CarouselCert,
    }
}


</script>

<style lang="less" scoped>
.header {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans JP';
    min-width: auto;
    width: 100%;
    background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/BG/BG%206.png) no-repeat center top;
    background-size: cover;

    @media only screen and (min-width: 375px) and (max-width:1024px) {

        background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/BG/BG%206.png) no-repeat center top;
    }

    .carousel {
        display: none;

        @media only screen and (min-width: 375px) and (max-width:1024px) {
            display: block;
        }

        @media only screen and (min-width: 1025px) and (max-width:1600px) {
            display: block;
            margin-top: 20px;
        }
    }

    .container {
        .title-txt {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: none;
            }

            display: flex;
            justify-content: center;
            padding-top: 120px;

            .main-txt {
                width: 1111px;
                height: 504px;
                border-radius: 30px;
                box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.15);
                background-image: linear-gradient(114deg, rgba(255, 255, 255, 0.6), rgba(243, 245, 249, 0.9));

                .top-txt {
                    padding-top: 80px;
                    font-family: NotoSansCJKjp;
                    font-size: 60px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #111;
                }

                .bottom {
                    display: flex;
                    justify-content: center;

                    .bottom-txt {
                        width: 919px;
                        font-family: NotoSansCJKjp;
                        font-size: 30px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        color: #535353;
                    }
                }



            }


        }

        .title-txt-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: block;
                padding-top: 48px;

                .container {
                    display: flex;
                    justify-content: center;

                    img {
                        width: 343px;
                        height: 286px;
                    }
                }
            }
        }

        .display-img {
            @media only screen and (min-width: 375px) and (max-width:1600px) {
                display: none;
            }


            .row-1 {
                padding-top: 160px;
                display: flex;
                justify-content: center;
                gap: 32px;
            }

            .row-2 {
                padding-top: 32px;
                display: flex;
                justify-content: center;
                gap: 32px;
                margin-bottom: 96px;
            }
        }
    }


}
</style>