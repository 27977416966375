<template>
  <section class="header">
    <div class="container">
      <div class="window-txt-container">
        <div class="window-txt">
          <img
            class="window"
            src="@/assets/kr/FormContact/Form-Contact-Text-KR.png"
            alt="text"
          />
        </div>
      </div>

      <form id="form" class="form">
        <div class="form-container">
          <div class="name-tel-container">
            <input
              class="name"
              name="name"
              type="text"
              v-model="name"
              placeholder="성함"
            />
            <input
              class="tel"
              name="tel"
              type="tel"
              v-model="tel"
              placeholder="연락처"
            />
          </div>

          <div
            class="message-error-container-mobile"
            :class="validationChecked"
            v-if="messageError"
          >
            <div class="message-error">
              <p class="tablet-mobile">성함과 연락처를 입력해주십시오</p>
            </div>
          </div>

          <div class="checkbox-submit-container">
            <input
              class="checkbox"
              name="checkbox"
              type="checkbox"
              placeholder="連絡先"
              v-model="checkbox"
            />
            <label class="checkbox-label" for="checkbox"
              >개인정보처리방침 동의</label
            >
            <button
              class="submit-btn"
              :class="submitPrimed"
              @click.prevent="handleSubmit"
            >
              상담신청
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="message-container" v-if="messageSuccess">
      <div class="message-success-container">
        <div class="message-success">
          <p>
            실시간 상담신청이 접수되었습니다. 담당자가 확인 후 연락드리겠습니다.
            감사합니다.
          </p>
        </div>
      </div>
    </div>
    <div
      class="message-error-container"
      :class="validationChecked"
      v-if="messageError"
    >
      <div class="message-error">
        <p class="window">성함과 연락처를 입력해주십시오</p>
      </div>
    </div>
    <div
      class="message-error-checkbox-container"
      :class="validationCheckedCheckBox"
      v-if="messageErrorCheckbox"
    >
      <div class="message-error">
        <p class="window">
          개인정보취급방침에 동의하셔야 견적문의를 할 수 있습니다.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { fa } from "@formkit/i18n";
import { checkbox } from "@formkit/inputs";

export default {
  data() {
    return {
      name: "",
      tel: "",
      checkbox: false,
      messageSuccess: false,
      messageError: false,
      messageErrorCheckbox: false,
    };
  },
  // provide: {
  //   name: this.name,
  //   tel: this.tel,
  //   checkbox: this.checkbox
  // },

  computed: {
    submitPrimed() {
      return this.name && this.tel && this.checkbox ? "primed" : "";
    },
    validationChecked() {
      return this.name && this.tel ? "fixed" : "";
    },
    validationCheckedCheckBox() {
      return this.checkbox ? "fixed" : "";
    },
  },

  methods: {
    formReset() {
      this.name = "";
      this.tel = "";
      this.checkbox = false;
      this.messageSuccess = false;
      this.messageError = false;
    },

    async handleSubmit() {
      console.log("I am clicked");
      if (this.name && this.tel && this.checkbox) {
        try {
          console.log("out here");
          const payload = {
            method: "post",
            url: "/api/v1/mail/send",
            data: {
              name: this.name,
              lang: "kr",
              tel: this.tel,
            },
          };
          const data = await this.axios(payload);
          console.log("results: ", data);
          if (data.status == 200) {
            this.messageError = false;
            this.messageSuccess = true;
            setTimeout(this.formReset, 3000);
          }
        } catch (error) {
          this.messageError = true;
          this.messageSuccess = false;
        }
      }
      if (!this.name || !this.tel) {
        this.messageError = true;
        this.messageErrorCheckbox = false;
        this.messageSuccess = false;
        if (!this.checkbox) {
          this.messageErrorCheckbox = false;
          setTimeout(this.resetWarning, 2000);
        }
      }
      if (this.name && this.tel && !this.checkbox) {
        this.messageError = false;
        this.messageErrorCheckbox = true;
        this.messageSuccess = false;
        setTimeout(this.resetWarning, 2000);
      }
    },
    resetWarning() {
      this.messageSuccess = false;
      this.messageError = false;
      this.messageErrorCheckbox = false;
    },
  },
};
</script>

<style lang="less" scoped>
p {
  margin-bottom: 0;
}

h3 {
  text-align: center;
}

.header {
  background-color: #004eff;
  padding-bottom: 48px;
  padding-top: 48px;

  @media only screen and (min-width: 375px) and (max-width: 976px) {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  @media only screen and (min-width: 976px) and (max-width: 1540px) {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  .message-container {
    display: flex;
    justify-content: center;
    position: relative;

    .message-success-container {
      z-index: 999;
      bottom: -20px;
      position: absolute;
      padding-left: 73px;

      @media only screen and (min-width: 375px) and (max-width: 976px) {
        padding-left: 0px;
        padding-bottom: 72px;
      }

      .message-success {
        @media only screen and (min-width: 375px) and (max-width: 976px) {
          width: 350px;
          height: 40px;
          margin: 24px 0 16px;
          padding: 10px 104px 10px 16px;
          border-radius: 8px;
          box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.15);
          background-color: #fff;
          display: flex;
          align-items: center;
        }

        width: 465px;
        height: 80px;
        margin: 0 18px 0 11px;
        padding: 8px 16px 8px 24px;

        border-radius: 8px;
        box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.15);
        background-color: #fff;

        p {
          font-family: NotoSansCJKjp;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          color: #57bf82;
        }
      }
    }
  }

  .message-error-container {
    @media only screen and (min-width: 375px) and (max-width: 976px) {
      display: none;
    }

    display: flex;
    justify-content: center;
    padding-right: 82px;
    position: relative;

    @media only screen and (min-width: 375px) and (max-width: 976px) {
      padding-left: 0px;
    }

    &.fixed {
      display: none;
    }

    .message-error {
      width: 307px;
      height: 48px;
      margin: 0 18px 0 11px;
      padding: 10px 0px 8px 0px;
      border-radius: 8px;
      box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.15);
      background-color: #fff;

      @media only screen and (min-width: 375px) and (max-width: 976px) {
        width: 250px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -11px;
      }

      p.window {
        @media only screen and (min-width: 375px) and (max-width: 976px) {
          display: none;
        }

        font-family: NotoSansCJKjp;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: red;
      }
    }
  }

  .message-error-container-mobile {
    display: none;
    justify-content: center;
    position: relative;

    @media only screen and (min-width: 375px) and (max-width: 976px) {
      display: flex;
      padding-left: 0px;
      margin-right: 106px;
      margin-bottom: 8px;
    }

    &.fixed {
      display: none;
    }

    .message-error {
      width: 250px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -11px;
      left: 34px;
      background-color: #fff;
      margin: 0 18px 0 11px;
      padding: 10px 0px 8px 0px;
      border-radius: 8px;
      box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.15);

      p.tablet-mobile {
        display: none;

        @media only screen and (min-width: 375px) and (max-width: 976px) {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: NotoSansCJKjp;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #d91d22;

          span {
            display: none;
          }
        }
      }
    }
  }

  .message-error-checkbox-container {
    display: flex;
    justify-content: center;
    padding-left: 149px;

    &.fixed {
      display: none;
    }

    @media only screen and (min-width: 375px) and (max-width: 976px) {
      padding-left: 0px;
    }

    .message-error {
      width: 543px;
      height: 48px;
      margin: 0 18px 0 11px;
      padding: 10px 0px 8px 0px;
      border-radius: 8px;
      box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.15);
      background-color: #fff;

      @media only screen and (min-width: 375px) and (max-width: 976px) {
        width: 375px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -11px;
        left: 34px;
        background-color: #fff;
        margin: 0 18px 0 11px;
        padding: 10px 0px 8px 0px;
        border-radius: 8px;
        box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.15);
      }

      p.window {
        font-family: NotoSansCJKjp;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: red;

        @media only screen and (min-width: 375px) and (max-width: 976px) {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: NotoSansCJKjp;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #d91d22;
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 375px) and (max-width: 1540px) {
      display: block;
    }

    .window-txt-container {
      @media only screen and (min-width: 375px) and (max-width: 976px) {
        display: flex;
        justify-content: center;
      }

      @media only screen and (min-width: 976px) and (max-width: 1540px) {
        display: flex;
        justify-content: center;
        padding-bottom: 16px;
      }

      .window-txt {
        .window {
          width: 100%;
          background-color: transparent;

          @media only screen and (min-width: 375px) and (max-width: 780px) {
            width: 280px;
            margin: 0 auto;
          }
        }
      }
    }

    .form {
      .form-container {
        display: flex;
        justify-content: center;

        @media only screen and (min-width: 375px) and (max-width: 976px) {
          display: block;
        }

        .name-tel-container {
          display: flex;
          justify-content: center;

          @media only screen and (min-width: 375px) and (max-width: 976px) {
            padding-top: 12px;
            padding-bottom: 12px;
          }

          .name {
            width: 220px;
            height: 48px;
            margin: 0 16px 0 11px;
            padding: 8px 0px 8px 16px;
            border-radius: 8px;
            background-color: #fff;

            @media only screen and (min-width: 375px) and (max-width: 976px) {
              width: 168px;
              height: 40px;
              padding: 10px 0px 10px 16px;
              border-radius: 8px;
              background-color: #fff;
            }
          }

          .tel {
            width: 220px;
            height: 48px;
            margin: 0 16px 0 11px;
            padding: 8px 0px 8px 16px;
            border-radius: 8px;
            background-color: #fff;

            @media only screen and (min-width: 375px) and (max-width: 976px) {
              width: 168px;
              height: 40px;
              padding: 10px 0px 10px 16px;
              border-radius: 8px;
              background-color: #fff;
            }
          }
        }

        .checkbox-submit-container {
          @media only screen and (min-width: 375px) and (max-width: 976px) {
            display: flex;
            justify-content: center;
          }

          .checkbox {
            @media only screen and (min-width: 375px) and (max-width: 976px) {
              width: 16px;
              height: 16px;
              margin-top: 10px;
            }
          }

          .checkbox-label {
            padding-left: 8px;
            font-family: NotoSansCJKjp;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
            color: #fff;

            @media only screen and (min-width: 375px) and (max-width: 976px) {
              display: flex;
              align-items: center;
              font-family: NotoSansCJKjp;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: #fff;
            }
          }
        }

        .submit-btn {
          width: 220px;
          height: 48px;
          margin: 0 0 0 32px;
          padding: 1px 54px 1px 55px;
          border-radius: 8px;
          background-color: #dadadc;
          color: #70737b;

          @media only screen and (min-width: 375px) and (max-width: 976px) {
            width: 168px;
            height: 40px;
            padding: 7px 28px 7px 29px;
            border-radius: 8px;
            background-color: #dadadc;
            color: #70737b;
          }

          &.primed {
            background-color: #d91d22;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
