<template>
    <div class="container" :class="showErrorMessage">
        hello
    </div>
</template>


<script>

</script>


<style lang="less" scoped>
.container {
    height: 144px;

    @media only screen and (min-width: 375px) and (max-width: 976px) {
        height: 158px;

    }
    @media only screen and (min-width: 976px) and (max-width: 977px) {
        height: 182px;

    }
    @media only screen and (min-width: 977px) and (max-width: 1541px) {
        height: 126px;

    }

}
</style>