<template>
    <section class="quotes-section">
        <div data-aos="fade-up" data-aos-duration="1500">
            <div  class="quotes-header">
                <img src="@/assets/kr/sec4_itm_01.png" />
                <img src="@/assets/kr/sec4_itm_02.png" />
                <img src="@/assets/kr/sec4_itm_03.png" />
            </div>

        </div>
        <div class="quotes-header-mobile">
            <img src="@/assets/kr/sec4_m_01.png" />
            <img src="@/assets/kr/sec4_m_02.png" />
            <img src="@/assets/kr/sec4_m_03.png" />
        </div>
    </section>
</template>

<style lang="less">
.quotes-section {
    background-image: url(@/assets/kr/sec4_bg.jpg);
    background-position: center center;
    background-size: cover;
    // background-attachment: fixed;

    .quotes-header-mobile {
        display: none;
    }

    .quotes-header {
        width: 100%;
        height: 100%;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
}

@media screen and (max-width:781px) {
    .quotes-header {
        display: none;
    }

    .quotes-section {
        background-image: url(@/assets/kr/sec4_bg_m.jpg) !important;
        background-position: center center;
        background-size: cover;

        .quotes-header-mobile {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;

            img {
                width: 100%;
            }
        }
    }


}
</style>