<template>
    <section class="header">
        <div class="container">
            <div class="banner-txt">
                <div class="line-1">
                    <p>
                        推薦人 <span> IBパートナー </span>!
                    </p>
                </div>
                <div class="line-2">
                    <p>Doo Prime と一緒に手を組んで発展してみてください。 </p>
                </div>
                <div class="line-3">
                    <p>Doo Prime
                        では外国為替/海外先物/指数/原材料/貴金属/暗号通貨/海外株式/など様々な銘柄が取引されており、顧客維持に役立つようパートナーの方々に前例のないリベート及び追加恩恵を提供しています。
                    </p>
                </div>
            </div>

            <div class="banner-txt-mobile">
                <div class="line-1">
                    <p>
                        推薦人<span> IBパートナー </span>!
                    </p>
                </div>
                <div class="line-2">
                    <p>Doo Prime と一緒に手を組んで <br> 発展してみてください。 </p>
                </div>

            </div>

            <div class="img-conversation">
                <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Desktop/Images/Content%205.png"
                    alt="messages">
            </div>
            <div class="img-conversation-mobile">
                <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Content%205.png"
                    alt="messages">
            </div>
            <div class="img-conversation-tablet">
                <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Desktop/Images/Content%205.png"
                    alt="messages">
            </div>

            <div class="bottom-txt">
                <div class="line-1">
                    <p>
                        ご紹介するお客様がいないと <span> <br></span> 心配しないでください！
                    </p>
                </div>
                <div class="line-2">
                    <p>
                        Doo Prime で推薦人パートナーの資格で自ら <span> <br></span> 取引してリベートを受け取ってください。
                    </p>
                </div>
            </div>

        </div>
    </section>
</template>

<script>


</script>


<style lang="less" scoped>
.header {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans JP';
    min-width: auto;
    width: 100%;
    background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/BG/BG%205.png) no-repeat center top;
    background-size: cover;
    position: relative;

    p {
        margin-top: 0;
        margin-bottom: 0em;
    }


    @media only screen and (min-width: 375px) and (max-width:1024px) {
        background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/BG/BG%205.png) no-repeat center top;

    }

    .container {
        .banner-txt {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: none;
            }

            .line-1 {
                padding-top: 97px;

                p {
                    font-family: NotoSansCJKjp;
                    font-size: 60px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;

                    span {
                        font-family: NotoSansCJKjp;
                        font-size: 60px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: center;
                        color: #ffc900;
                    }
                }
            }

            .line-2 {
                padding-top: 24px;

                p {
                    font-family: NotoSansCJKjp;
                    font-size: 30px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                }
            }

            .line-3 {
                display: flex;
                justify-content: center;
                padding-top: 24px;

                p {

                    font-family: NotoSansCJKjp;
                    font-size: 20px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.75;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    width: 948px;

                }
            }
        }

        .banner-txt-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: block;

                .line-1 {
                    padding-top: 48px;
                    font-family: NotoSansCJKjp;
                    font-size: 28px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;

                    span {
                        color: #ffc600;
                    }
                }

                .line-2 {
                    font-family: NotoSansCJKjp;
                    font-size: 18px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    padding-top: 16px;
                }
            }
        }



        .img-conversation {
            display: flex;
            justify-content: center;
            padding-top: 49px;

            @media only screen and (min-width: 375px) and (max-width:1600px) {
                display: none;
            }
        }

        .img-conversation-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: flex;
                justify-content: center;
                padding-top: 49px;

                img {
                    width: 343px;
                    height: 171px;
                }
            }
        }

        .img-conversation-tablet {
            display: none;

            @media only screen and (min-width: 1025px) and (max-width:1600px) {
                display: flex;
                justify-content: center;
                padding-top: 49px;

                img {
                    width: 1000px;
                }
            }
        }

        .bottom-txt {
            .line-1 {
                padding-top: 112px;
                font-family: NotoSansCJKjp;
                font-size: 40px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                padding-bottom: 8px;

                span {
                    display: none;
                }

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    padding-top: 64px;
                    font-family: NotoSansCJKjp;
                    font-size: 22px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.45;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    padding-bottom: 16px;

                    span {
                        display: block;
                        height: 0;
                    }
                }


            }

            .line-2 {
                padding-bottom: 96px;
                font-family: NotoSansCJKjp;
                font-size: 22px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.82;
                letter-spacing: normal;
                text-align: center;
                color: #fff;

                span {
                    display: none;
                }

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    font-family: NotoSansCJKjp;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    padding-bottom: 48px;

                    span {
                        display: block;
                        display: block;
                        height: 0;
                    }
                }
            }
        }
    }

}
</style>