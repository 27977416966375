<template>
    <div class="container">

        <FormContact></FormContact>

    </div>

</template>

<script>

import FormContact from "./FormContact.vue";

export default {
    components: {

        FormContact
    }
}

</script>


<style scoped lang="less">
.container {
    z-index: 9999;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}
</style>