<template>



    <section class="header">

        <div class="container">

            <div class="display-cert-container" :class="displayDarkBackground">
                <div class="header-txt">
                    <p>信頼できる企業</p>
                </div>
                <div class="doo-logo">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/DP%20Logo%20-%20Black%20Text.png"
                        alt="dooprime-logo">
                </div>
                <div class="doo-logo-mobile">
                    <div class="flex-container">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/New%20Assets/Group%2016.png"
                            alt="doo-prime-txt">
                    </div>
                </div>
                <div class="middle-txt">
                    <p>
                        Doo Prime は、金融取引サービスの提供に重点を置いたグローバルオンライン仲介会社です。
                    </p>
                </div>
                <div class="bottom-txt">
                    <p>Doo Prime は香港、ダラス、シンガポール、クアラルンプール、台北、ベトナム、モーリシャス、セーシェル、バヌアツなどに事務所が設立されており、イギリス金融監督機関
                        <span>FCA</span>
                        、バヌアツ金融委員会
                        <span>VFSC</span> 、セーシェル <span>FSA</span> など金融監督規制を受けている正式に登録しだ証券会社であり、バヌアツ法律管轄で一人当り最大
                        <span>500,000$</span>
                        に該当する専門責任賠償保険に加入し、顧客に安全な仲介取引を提供できるようになりました。
                    </p>
                </div>
            </div>

            <div class="bottom-img" :class="displayDarkBackground">
                <button :disabled="isDisabled" @click="displayLicense" class="img-1">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/License%20-%20SC%20FSA.png"
                        alt="SC-FSA-img">
                    <p class="title">SC FSA</p>
                    <p class="desc">規制番号 (SD090)</p>
                    <div class="line">
                        <div class="blue-line"></div>
                    </div>
                </button>
                <button :disabled="isDisabled" @click="displayLicenseUKFCA" class="img-1">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Desktop/Images/License%20-%20UK%20FCA.png"
                        alt="SC-FSA-img">
                    <p class="title">UK FCA</p>
                    <p class="desc">規制番号 (833414)</p>
                    <div class="line">
                        <div class="blue-line"></div>
                    </div>
                </button>
                <button :disabled="isDisabled" @click="displayLicenseVUFSC" class="img-1">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Desktop/Images/License%20-%20VU%20FSC.png"
                        alt="SC-FSA-img">
                    <p class="title">VU FSC</p>
                    <p class="desc">規制番号 (700238)</p>
                    <div class="line">
                        <div class="blue-line"></div>
                    </div>
                </button>
                <button :disabled="isDisabled" @click="displayLicenseMUFSC" class="img-1">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Desktop/Images/License%20-%20MU%20FSC.png"
                        alt="SC-FSA-img">
                    <p class="title">MU FSC</p>
                    <p class="desc">規制番号 (C119023907)</p>
                    <div class="line">
                        <div class="blue-line"></div>
                    </div>
                </button>
            </div>


            <div class="popup-img-container-window" v-if="displayFSA">
                <div class="btn-container">
                    <button @click="closeDisplay" class="popup-btn">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Close%20Icon.png"
                            alt="close-btn">
                    </button>
                </div>

                <LicenseSCFSA></LicenseSCFSA>
            </div>
            <div class="popup-img-container-window" v-if="displayUKFCA">
                <div class="btn-container">
                    <button @click="closeDisplay" class="popup-btn">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Close%20Icon.png"
                            alt="close-btn">
                    </button>
                </div>
                <LicenseUKFCA></LicenseUKFCA>
            </div>
            <div class="popup-img-container-window" v-if="displayVUFSC">
                <div class="btn-container">
                    <button @click="closeDisplay" class="popup-btn">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Close%20Icon.png"
                            alt="close-btn">
                    </button>
                </div>
                <LicenseVUFSC></LicenseVUFSC>
            </div>
            <div class="popup-img-container-window" v-if="displayMUFSC">
                <div class="btn-container">
                    <button @click="closeDisplay" class="popup-btn">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Close%20Icon.png"
                            alt="close-btn">
                    </button>
                </div>
                <LicenseMUFSC></LicenseMUFSC>
            </div>

            <div class="bottom-img-mobile">
                <CarouselLicense></CarouselLicense>
                <!-- <CarouselLicenseView></CarouselLicenseView> -->
            </div>

        </div>
    </section>

</template>


<script>
import CarouselLicense from "./Carousel/CarouselLicense.vue";
import LicenseUKFCA from "./LicenseView.vue/LicenseUKFCA.vue";
import LicenseVUFSC from "./LicenseView.vue/LicenseVUFSC.vue";
import LicenseMUFSC from "./LicenseView.vue/LicenseMUFSC.vue";
import LicenseSCFSA from "./LicenseView.vue/LicenseSCFSA.vue";
// import CarouselLicenseView from "./Carousel/CarouselLicenseView.vue";

export default {
    data() {
        return {
            displayFSA: false,
            displayUKFCA: false,
            displayVUFSC: false,
            displayMUFSC: false,
            displayLicenseBackground: false,
            isDisabled: false
        }
    },

    computed: {
        displayDarkBackground() {
            return this.displayLicenseBackground ? 'licenseView' : ''
        }
    },

    components: {
        CarouselLicense,
        LicenseUKFCA,
        LicenseVUFSC,
        LicenseMUFSC,
        LicenseSCFSA,
        // CarouselLicenseView
    },

    methods: {
        displayLicense() {
            console.log('displayLicense')
            this.displayFSA = !this.displayFSA
            this.displayLicenseBackground = !this.displayLicenseBackground
            this.isDisabled = true

        },
        displayLicenseUKFCA() {
            console.log('displayLicenseUKFCA')
            this.displayUKFCA = !this.displayUKFCA
            this.displayLicenseBackground = !this.displayLicenseBackground
            this.isDisabled = true

        },
        displayLicenseVUFSC() {
            console.log('displayLicenseVUFSC')
            this.displayVUFSC = !this.displayVUFSC
            this.displayLicenseBackground = !this.displayLicenseBackground
            this.isDisabled = true

        },
        displayLicenseMUFSC() {
            console.log('displayLicenseMUFSC')
            this.displayMUFSC = !this.displayMUFSC
            this.displayLicenseBackground = !this.displayLicenseBackground
            this.isDisabled = true

        },
        closeDisplay() {
            this.displayFSA = false,
                this.displayUKFCA = false,
                this.displayVUFSC = false,
                this.displayMUFSC = false,
                this.displayLicenseBackground = false
            this.isDisabled = false
        }
    }
}




</script>


<style lang="less" scoped>
.header {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans JP';
    min-width: auto;
    width: 100%;
    background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/BG/BG%204.png) no-repeat center top;
    background-size: cover;
    position: relative;


    @media only screen and (min-width: 375px) and (max-width:1024px) {

        background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/BG/BG%204.png) no-repeat center top;
    }

    p {
        margin-bottom: 0;
    }

    .container {
        position: relative;




        .display-cert-container {
            &.licenseView {
                opacity: 0.5;
                background-color: #000;
            }

            .header-txt {
                padding-top: 96px;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    padding-top: 48px;
                    padding-bottom: 32px;
                }

                p {
                    @media only screen and (min-width: 375px) and (max-width:1024px) {
                        font-family: NotoSansCJKjp;
                        font-size: 28px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        // text-align: center;
                        display: flex;
                        justify-content: space-around;
                        color: #000;
                    }

                    font-family: NotoSansCJKjp;
                    font-size: 60px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #111;
                }

            }

            .doo-logo {
                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    display: none;
                }

                display: flex;
                justify-content: center;

                img {
                    padding-top: 80px;
                }
            }

            .doo-logo-mobile {
                display: none;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    display: block;

                    .flex-container {
                        display: flex;
                        justify-content: center;
                        img{
                            width: 343px;
                            height: 433px;
                        }
                    }
                }
            }

            .middle-txt {
                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    display: none;
                }

                padding-top: 64px;
                padding-bottom: 24px;
                display: flex;
                justify-content: center;

                p {
                    width: 771px;
                    font-family: NotoSansCJKjp;
                    font-size: 30px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000;
                }
            }

            .bottom-txt {
                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    display: none;
                }

                display: flex;
                justify-content: center;

                p {
                    width: 820px;
                    font-family: NotoSansCJKjp;
                    font-size: 20px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.75;
                    letter-spacing: normal;
                    text-align: center;
                    color: #535353;

                    span {
                        font-weight: 900;
                        color: #0a65ff;
                    }
                }
            }
        }



        .bottom-img {


            &.licenseView {
                opacity: 0.5;
                background-color: #000;
            }

            @media only screen and (min-width: 375px) and (max-width:1600px) {
                display: none;
            }

            display: flex;
            justify-content: center;
            gap: 16px;
            padding-top: 48px;
            padding-bottom: 96px;

            .img-1 {
                width: 292px;
                height: 228px;
                padding-top: 32px;
                border-radius: 30px;
                box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.15);
                background-color: #fcfcfc;
                border: none;

                img {
                    padding-left: 20px;
                }

                .title {
                    font-family: NotoSansCJKjp;
                    font-size: 20px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.75;
                    letter-spacing: normal;
                    text-align: center;
                    color: #000;
                    padding-top: 16px;
                }

                .desc {
                    font-family: NotoSansCJKjp;
                    font-size: 20px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.75;
                    letter-spacing: normal;
                    text-align: center;
                    color: #535353;
                }

                .line {
                    padding-left: 20px;

                    .blue-line {
                        width: 96px;
                        height: 2px;
                        margin: 4px 72px 0;
                        background-color: #0a65ff;
                    }

                }
            }
        }

        .bottom-img-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1600px) {
                display: block;
                padding-bottom: 20px;
            }
        }

        .popup-img-container-window {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: none;
            }


            .btn-container {
                display: flex;
                justify-content: center;
                padding-left: 620px;

                .popup-btn {
                    background-color: transparent;
                    border: none;
                    color: red;
                    position: absolute;
                    z-index: 100;
                    top: 115px;

                    img {
                        width: 32px;
                        height: 32px;
                    }


                }
            }


        }
    }
}
</style>