<template>
    <div class="container">
        <FormContactKR></FormContactKR>
        

    </div>

</template>

<script>
import FormContactKR from './FormContactKR.vue';


export default {
    components: {
    FormContactKR
}
}

</script>


<style scoped lang="less">
.container {
    z-index: 9999;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}
</style>