<template>

    <section class="header-popup-image">
        <div class="container">
            <div class="img-license">
                <img class="MUFSC"
                    src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/License/MU%20FSC.jpg"
                    alt="MUFSC">
            </div>
        </div>
    </section>

</template>


<script>


</script>


<style lang="less" scoped>
.header-popup-image {




    .container {
        display: flex;
        justify-content: center;
        align-items: center;

        .img-license {
            position: absolute;
            bottom: 150px;

            .MUFSC {
                width: 509px;
                height: 720px;
                box-shadow: 1px 7px 16px 0 rgba(46, 46, 46, 0.4);

            }
        }
    }
}
</style>