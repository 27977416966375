<script>
import axios from 'axios';


export default {
    name: 'HeaderLocationCheck',
    mounted: async () => {
        const {data} = await axios({
            method: 'GET',
            url: '/api/v1/location'
        })
        if (data.data.country_code == 'KR') window.location.replace('https://dooprime.kr')
        if (data.data.country_code == 'JP') window.location.replace('https://dooprime.com')
        if (data.data.country_code == 'CN') window.location.replace('https://dooprimedir01.com')

        window.location.replace('https://dooprime.com')
    }
}
</script>
