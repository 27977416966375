<template>
    <section class="header">
        <div class="container">
            <div class="social-link-images">
                <img src="@/assets/kr/SocialLinks/SocialLinksKR.png" alt="SocialLinks">
            </div>
            <div class="youtube">
                <a href="https://www.youtube.com/channel/UCAVzasBZEEd4Gk0O5nxbOzg"></a>
            </div>
            <div class="blog">
                <a href="https://blog.naver.com/dooprime1"></a>
            </div>
            <div class="talk">
                <a href="https://open.kakao.com/o/sGCFYiIc"></a>
            </div>
        </div>
        <div class="container-mobile">
            <div class="social-link-images">
                <img src="@/assets/kr/SocialLinks//Social-links-KR-mobile.jpg" alt="SocialLinks">
            </div>
            <div class="youtube">
                <a href="https://www.youtube.com/channel/UCAVzasBZEEd4Gk0O5nxbOzg"></a>
            </div>
            <div class="blog">
                <a href="https://blog.naver.com/dooprime1"></a>
            </div>
            <div class="talk">
                <a href="https://open.kakao.com/o/sGCFYiIc"></a>
            </div>
        </div>
    </section>

</template>

<style lang="less" scoped>
.header {
    position: relative;

    .container {
        @media only screen and (min-width: 375px) and (max-width:780px) {
            display: none;
        }

        .social-link-images {
            img {
                width: 100%;
            }
        }

        .youtube {
            a {
                width: 19.5%;
                height: 63%;
                top: 20%;
                left: 18.8%;
                border-radius: 50px;
                position: absolute;
                z-index: 1;
                display: flex;
            }
        }

        .blog {
            a {
                width: 19.5%;
                height: 63%;
                top: 20%;
                left: 41%;
                border-radius: 50px;
                position: absolute;
                z-index: 1;
                display: flex;
            }
        }

        .talk {

            a {
                width: 19.5%;
                height: 63%;
                top: 20%;
                left: 62.8%;
                border-radius: 50px;
                position: absolute;
                z-index: 1;
                display: flex;
            }

        }

    }

    .container-mobile {
        display: none;

        @media only screen and (min-width: 375px) and (max-width:780px) {
            display: block;
        }

        .social-link-images {
            img {
                width: 100%;
            }
        }

        .youtube {

            a {
                width: 62%;
                height: 23%;
                top: 10.4%;
                left: 18.8%;
                border-radius: 50px;
                position: absolute;
                z-index: 1;
            }

        }

        .blog {

            a {
                width: 62%;
                height: 23%;
                top: 39.2%;
                left: 18.8%;
                border-radius: 50px;
                position: absolute;
                z-index: 1;
            }

        }

        .talk {

            a {
                width: 62%;
                height: 23%;
                top: 66.6%;
                left: 18.8%;
                border-radius: 50px;
                position: absolute;
                z-index: 1;
            }

        }





    }
}
</style>