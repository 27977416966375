<template>

    <section class="header">
        <div class="container">
            <div class="header-txt">
                <div class="img">
                    <img class="copy-img"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/ChartView/copy-img.svg"
                        alt="copy">
                    <img class="copy-img-mobile"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Copy.png"
                        pagespeed_no_transform
                        alt="copy">
                </div>
                <p class="head-txt-1">トレードフォロー専門者の</p>
                <div class="text-with-box">
                    <div class="yellow-box"></div>
                    <div class="yellow-box-mobile"></div>
                    <p class="head-txt-2">
                        実際収益率を公開
                    </p>
                </div>
            </div>
            <div class="images">
                <div class="img-container">
                    <div class="img-graph">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/R1%20Assets/Table_Desktop.png"
                            pagespeed_no_transform
                            alt="graphs">
                    </div>
                </div>
            </div>
            <div class="images-mobile">
                <div class="img-container">
                    <div class="img-graph">
                        <img class="graph"
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/R1%20Assets/Table_Mobile.png"
                            alt="graphs"
                            pagespeed_no_transform
                            >
                    </div>
                </div>
            </div>
            <div class="images-tablet">
                <div class="img-container">
                    <div class="img-graph">
                        <img class="graph"
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/TableChart-View/Table%20with%20content.png"
                            alt="graphs">
                    </div>
                </div>
            </div>
            <div class="disclaimer">
                <p>
                    <span>* </span> このイメージは実際の取引者ランキングに基づいて作成された内容であり、特定取引者の既存の取引内容データはトレードフォローのシステムで直接確認できます。
                </p>
            </div>
        </div>
    </section>

</template>

<script>


export default {



}


</script>


<style lang="less" scoped>
.header {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans JP';
    min-width: auto;
    width: 100%;
    background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/ChartView/Chart-View-Background.webp) no-repeat center top;
    background-size: cover;
    position: relative;

    p {
        margin-bottom: 0;
    }

    @media only screen and (min-width: 375px) and (max-width:1024px) {
        background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/BG/BG%202.png) no-repeat center top;

    }

    .header-txt {
        position: relative;

        .img {
            display: flex;
            justify-content: center;

            .copy-img {
                position: absolute;
                padding-top: 67px;
                padding-right: 735px;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    display: none;
                }
            }

            .copy-img-mobile {
                display: none;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    display: block;
                    position: absolute;
                    padding-top: 45px;
                    padding-right: 339px;
                }
            }

        }


        .head-txt-1 {
            font-family: NotoSansCJKjp;
            font-size: 60px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: center;
            color: #111;
            padding-top: 120px;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                font-family: NotoSansCJKjp;
                font-size: 28px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #000;
                padding-top: 64px;
            }
        }

        .text-with-box {
            display: flex;
            justify-content: center;

            .yellow-box {
                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    display: none;
                }

                position: absolute;
                height: 38px;
                width: 388px;
                background-color: #ffeb7b;
                z-index: 1;
                justify-content: center;
                margin-top: 50px;
                margin-right: 225px;
            }

            .yellow-box-mobile {
                display: none;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    display: block;
                }

                position: absolute;
                width: 187px;
                height: 16px;
                background-color: #ffeb7b;
                z-index: 1;
                justify-content: center;
                margin-top: 30px;
                margin-right: 115px;
            }

            .head-txt-2 {
                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    font-family: NotoSansCJKjp;
                    font-size: 36px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #111;
                }

                font-family: NotoSansCJKjp;
                font-size: 75px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.07;
                letter-spacing: normal;
                text-align: center;
                color: #111;
                z-index: 999;
            }
        }


    }

    .images {
        @media only screen and (min-width: 375px) and (max-width:1600px) {
            display: none;
        }

        padding-top: 55px;
        display: flex;
        justify-content: center;
    }

    .images-mobile {
        display: none;

        @media only screen and (min-width: 375px) and (max-width:1024px) {
            display: block;
            padding-top: 40px;
            padding-left: 16px;

            .img-container {
                display: flex;
                justify-content: center;

                .img-graph {

                    // width: 359px;
                    // overflow-x: scroll;

                    .graph {
                        // object-fit: contain;
                        // height: 424px;
                        width: 343px;
                        height: 384px;
                    }

                }

            }
        }

    }

    .images-tablet {
        display: none;

        @media only screen and (min-width: 1025px) and (max-width:1600px) {
            display: block;
            padding-top: 40px;
            padding-left: 16px;

            .img-container {
                display: flex;
                justify-content: center;

                .img-graph {

                    width: 920px;
                    overflow-x: scroll;

                    .graph {
                        object-fit: contain;
                        height: 503px;
                    }

                }

            }
        }
    }

    .disclaimer {
        display: flex;
        justify-content: center;


        p {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                font-family: NotoSansCJKjp;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #5d5d5d;
                width: 343px;
                padding-bottom: 48px;
            }

            @media only screen and (min-width: 1025px) and (max-width:1600px) {
                font-family: NotoSansCJKjp;
                font-size: 19px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #5d5d5d;
                width: 898px;
                padding-bottom: 48px;
            }

            width: 1499px;
            font-family: NotoSansCJKjp;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #535353;
            padding-top: 24px;
            padding-bottom: 96px;

            span {
                color: red;
            }
        }
    }
}
</style>
