<template>
  <section class="header">


    <div class="container">
      <div class="nav">
        <a href="https://www.dooprime.com/jp/" class="home-btn">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/06.svg" alt="dooprime logo">
        </a>
        <a href="https://www.dooprime.com/en/" class="home-btn-mobile">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/Images/DP%20Logo%20-%20White%20Text.png"
            alt="dooprime logo">
        </a>
        <button class="register-btn">
          <a href="https://user.dooprimelink.com/signup/index?hl=jp ">
            会員登録
          </a>
        </button>
      </div>
      <div class="investing-com">
        <div class="line-1">
        </div>
        <div class="investing-logo window-view">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/group-19.webp"
            alt="investing.com">
        </div>
        <div class="investing-logo mobile-view">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/Images/Investing%20Banner.png"
            alt="investing.com">
        </div>
        <div class="line-2">
        </div>
      </div>
      <div class="conversation">
        <div class="conversation-img ">
          <img class="window-view"
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/text-message.svg"
            alt="dooprime-conversation">
          <img class="mobile-view"
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/Images/Content%201.png"
            alt="dooprime-conversation">
        </div>
      </div>

      <div class="hero-txt">
        <h1 class="yellow-txt">
          グローバルトップ <span class="yellow-break"> <br> </span> トレードの


        </h1>
        <h1 class="white-txt">
          取引をそのままし <span class="yellow-break"> <br> </span>てみてください。
        </h1>
      </div>
      <div class="variety-box-container">
        <div class="variety-box">
          <div class="box-1-position tablet-view">

            <img class="box-1"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/blue-box-variety.svg"
              alt="variety-box-1">

            <h1 class="text-box-1">トレードフォロー顧客事例1</h1>

            <img class="inner-img-1"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/inner-img-1.webp"
              alt="inner-trading-img">

            <img class="circle-texts"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Assets_Circle/Desktop/Content%201.png"
              alt="circle-with-txt">

            <p class="text-paragraph">82年生まれ専業主婦の中原さんは家事を手伝うために投資を考えましたが、育児と家事のためトレーディングに集中しにくかったです。
              しかし、フォロートレーディング取引を始めてから毎日自分の収益率チェックと一週間1、2時間で専門家の比較検索だけで家事にとても役立ちました。</p>

          </div>


          <div class="box-2-position tablet-view">

            <img class="box-2"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/blue-box-variety.svg"
              alt="variety-box-1">

            <h1 class="text-box-2">トレードフォロー顧客事例２</h1>

            <img class="inner-img-2"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/inner-img-2.webp"
              alt="inner-trading-img">

            <img class="circle-texts"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Assets_Circle/Desktop/Content%202.png"
              alt="circle-with-txt">

            <p class="text-paragraph">65年生まれの尾崎さんは3年前退職後、小さなチキン店をオープンしましたが、コロナの影響で1年も経たないうちに廃業することになりました。
              幸いにも知人を通じて Doo Prime のフォロートレーディングに接することになり、短期間で驚くべき収益率で再び人生の自信を取り戻したそうです。</p>
          </div>


          <div class="box-3-position">

            <img class="box-3"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/blue-box-variety.svg"
              alt="variety-box-1">

            <h1 class="text-box-3">トレードフォロー顧客事例３</h1>

            <img class="inner-img-3"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/inner-img-3.webp"
              alt="inner-trading-img">

            <img class="circle-texts"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Assets_Circle/Desktop/Content%203.png"
              alt="circle-with-txt">

            <p class="text-paragraph">80年生まれの清水さんは株式投資、暗号通貨ペーなどの投資に失敗し、半信半疑でDooPrimeのフォロートレーディングを始めました。
              1年以上の収益率に満足し、現在は家族や知人など多くのお客様まで紹介し、Doo Prime の推薦人パートナーとして活動しています。</p>


          </div>

        </div>
      </div>

      <div class="variety-box-container-mobile">
        <div class="img-container">
          <div class="img-1">
            <img
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/New%20Assets/Group%2014.png"
              alt="investment-1">
          </div>
          <div class="img-2">
            <img
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/New%20Assets/Group%2015.png"
              alt="investment-1">
          </div>
        </div>
      </div>

      <div class="disclaimer">
        <p class="disclaimer-txt">
          <span>*</span> 含まれているイメージは、少数の投資家の実際の経済状況に基づいて制作され、トレードフォロー者お客様の同意の上で公開される口座内容です。
        </p>
        <div class="coin-container">
          <div class="coins-img">
            <img class="coins"
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Coins/coins.webp" alt="coins">
          </div>
        </div>
      </div>

      <div class="disclaimer-mobile">
        <div class="flex-container">
          <p class="disclaimer-txt">
            <span>*</span> 含まれているイメージは、少数の投資家の実際の経済状況に基づいて制作され、トレードフォロー者お客様の同意の上で公開される口座内容です。
          </p>

        </div>

      </div>

      <div class="disclaimer-tablet">
        <div class="flex-container">
          <p class="disclaimer-txt">
            <span>*</span> 含まれているイメージは、少数の投資家の実際の経済状況に基づいて制作され、トレードフォロー者お客様の同意の上で公開される口座内容です。
          </p>

        </div>

      </div>





    </div>
  </section>
</template>


<script>

export default {

  methods: {
    stickyNavBar() {
      window.onscroll = () => {
        let navStyle = document.querySelector(".nav");
        if (window.scrollY > 5) {
          navStyle.classList.add("sticky")
        } else {
          navStyle.classList.remove("sticky");
        }
      }
    },
  },
  mounted() {
    document.body.addEventListener('scroll', this.stickyNavBar())
  },
}



</script>

<style lang="less" scoped>
@cdn: 'http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Top-Banner/';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap');


.header {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans JP';
  min-width: auto;
  width: 100%;
  background: url("http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/BG/Header_Desktop_No%20Text.png") no-repeat center top;
  background-size: cover;
  position: relative;

  .sticky {
    z-index: 99999;
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.7);
    padding-bottom: 20px !important;
  }

  h1,
  p {
    margin-top: 0;
    margin-bottom: 0em;
  }

  @media only screen and (min-width: 375px) and (max-width:1024px) {
    background: url("http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/BG/Header_Mobile_No-Text.jpg") no-repeat center top ;
    background-size: 1024px 1608px;
  }






  .container {

    .nav {


      display: flex;
      justify-content: space-between;
      padding-top: 40px;

      @media only screen and (min-width: 375px) and (max-width:1024px) {
        padding-top: 22px;
        justify-content: space-around;
      }

      .home-btn {
        padding-left: 64px;

        @media only screen and (min-width: 375px) and (max-width:1024px) {
          display: none;
        }
      }

      .home-btn-mobile {
        display: none;

        @media only screen and (min-width: 375px) and (max-width:1024px) {
          padding-left: 0px;
          display: block;
        }
      }

      .register-btn {
        margin-right: 64px;
        background-color: transparent;
        width: 124px;
        height: 48px;
        border-radius: 24px;
        border: solid 1px #fff;

        @media only screen and (min-width: 375px) and (max-width:1024px) {
          margin-right: 0px;
          width: 96px;
          height: 36px;
          border-radius: 18px;
          border: solid 1px #fff;
        }

        a {
          text-decoration: none;
          color: #fff;


        }
      }


    }

    .investing-com {
      display: flex;
      justify-content: center;

      @media only screen and (min-width: 375px) and (max-width:1024px) {
        padding-top: 34px;
      }

      .mobile-view {
        display: none;
      }

      .line-1 {
        min-width: 276px;
        height: 2px;
        margin-left: 461px;
        margin-right: 40px;
        margin-top: 27px;

        opacity: 0.6;
        background-color: #fff;

      }

      .line-2 {
        min-width: 276px;
        height: 2px;
        margin-right: 461px;
        margin-left: 40px;
        margin-top: 27px;
        opacity: 0.6;
        background-color: #fff;
      }

      @media only screen and (min-width: 375px) and (max-width:1024px) {
        .investing-logo.mobile-view {
          display: block;

          img {
            width: 222px;
            height: 36px;
          }
        }

        .investing-logo.window-view {
          display: none;
        }

        .line-1 {
          min-width: 110px;
          height: 2px;
          margin-left: 461px;
          margin-right: 16px;
          margin-top: 18px;
          opacity: 0.6;
          background-color: #fff;
        }

        .line-2 {
          min-width: 110px;
          height: 2px;
          margin-right: 461px;
          margin-left: 16px;
          margin-top: 18px;
          opacity: 0.6;
          background-color: #fff;
        }
      }


    }

    .conversation {


      .conversation-img {
        display: flex;
        width: 100%;
        max-width: auto;
        justify-content: center;

        @media only screen and (min-width: 375px) and (max-width:1024px) {
          img.window-view {
            display: none;
          }

          img.mobile-view {
            display: block;
            width: 255px;
            height: 144px;
            margin-top: 48px;
            margin-right: 79px;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
          }
        }

        img {
          padding-top: 120px;
          padding-right: 328px;

          @media only screen and (min-width: 375px) and (max-width:1024px) {

            padding-top: 48px;
            padding-right: 90px;
          }

          @media only screen and (min-width: 1025px) and (max-width:1600px) {
            padding-top: 45px;
            padding-right: 100px;
          }
        }

        .mobile-view {
          display: none;
        }

      }
    }

    .hero-txt {
      padding-top: 80px;

      @media only screen and (min-width: 375px) and (max-width:1024px) {
        padding-top: 32px;
      }

      .yellow-txt {
        color: #ffcc00;
        font-family: "NotoSansCJK";
        font-size: 60px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;



        span {
          display: none;
        }

        @media only screen and (min-width: 375px) and (max-width:1024px) {

          span {
            display: block;
            height: 0
          }
        }
      }

      @media only screen and (min-width: 375px) and (max-width:1024px) {
        .yellow-txt {
          font-family: 'Noto Sans JP';
          font-size: 28px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #ffcc00;
        }



      }


      .white-txt {
        font-family: 'Noto Sans JP';
        font-size: 60px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }

      @media only screen and (min-width: 375px) and (max-width:1024px) {
        .white-txt {
          font-family: 'Noto Sans JP';
          font-size: 28px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
      }
    }

    .variety-box-container {
      @media only screen and (min-width: 375px) and (max-width:1024px) {
        display: none;
      }

      .variety-box {
        display: flex;
        padding-top: 54px;
        // you can make image stagnant by using center on justify-content and manually adjust the gap
        justify-content: center;
        width: auto;
        gap: 24px;

        .box-1-position {
          position: relative;
          text-align: center;

          .box-1 {
            position: relative;
          }

          .text-box-1 {
            position: absolute;
            color: black;
            top: 0;
            padding-left: 49px;
            padding-top: 16px;
            font-family: NotoSansCJKjp;
            font-size: 30px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #fff;
          }

          .inner-img-1 {
            position: absolute;
            z-index: 1001;
            left: 0;
            padding-top: 112px;
            padding-left: 40px;
          }

          .circle-texts {
            position: absolute;
            width: 203px;
            height: 203px;
            margin-right: 24px;
            line-height: 500px;
            border-radius: 50%;
            font-size: 50px;
            color: #fff;
            text-align: center;
            background: #000;
            top: 0;
            right: 0;
            z-index: 1002;
            margin-top: 132px;
          }

          .text-paragraph {
            width: 472px;
            position: absolute;
            padding-bottom: 42px;
            padding-left: 34px;
            padding-right: 34px;
            bottom: 0;
            left: 0;
            padding-right: 34px;
            font-family: NotoSansCJKjp;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #535353;
          }

        }

        .box-2-position {
          position: relative;
          text-align: center;

          .text-box-2 {
            position: absolute;
            color: black;
            top: 0;
            padding-left: 49px;
            padding-top: 16px;
            font-family: NotoSansCJKjp;
            font-size: 30px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #fff;
          }

          .inner-img-2 {
            position: absolute;
            z-index: 1001;
            left: 0;
            padding-top: 112px;
            padding-left: 40px;
          }

          .circle-texts {
            position: absolute;
            width: 203px;
            height: 203px;
            margin-right: 24px;
            line-height: 500px;
            border-radius: 50%;
            font-size: 50px;
            color: #fff;
            text-align: center;
            background: #000;
            top: 0;
            right: 0;
            z-index: 1002;
            margin-top: 132px;
          }

          .text-paragraph {
            width: 472px;
            position: absolute;
            padding-bottom: 42px;
            padding-left: 34px;
            padding-right: 34px;
            bottom: 0;
            left: 0;
            padding-right: 34px;
            font-family: NotoSansCJKjp;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #535353;
          }
        }

        .box-3-position {
          position: relative;
          text-align: center;

          @media only screen and (min-width: 375px) and (max-width:1600px) {
            display: none;
          }

          .text-box-3 {
            position: absolute;
            color: black;
            top: 0;
            padding-left: 49px;
            padding-top: 16px;
            font-family: NotoSansCJKjp;
            font-size: 30px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            color: #fff;
          }

          .inner-img-3 {
            position: absolute;
            z-index: 1001;
            left: 0;
            padding-top: 112px;
            padding-left: 40px;
          }

          .circle-texts {
            position: absolute;
            width: 203px;
            height: 203px;
            margin-right: 32px;
            line-height: 500px;
            border-radius: 50%;
            font-size: 50px;
            color: #fff;
            text-align: center;
            background: #000;
            top: 0;
            right: 0;
            z-index: 1002;
            margin-top: 132px;
          }

          .text-paragraph {
            width: 472px;
            position: absolute;
            padding-bottom: 42px;
            padding-left: 34px;
            bottom: 0;
            left: 0;
            padding-right: 34px;
            font-family: NotoSansCJKjp;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #535353;
          }



        }
      }
    }

    .variety-box-container-mobile {
      display: none;

      @media only screen and (min-width: 375px) and (max-width:1024px) {
        display: block;
        padding-top: 40px;

        .img-container {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 24px;

          img {
            width: 343px;
            height: 453px;
          }
        }
      }

    }




    .disclaimer {
      @media only screen and (min-width: 375px) and (max-width:1600px) {
        display: none;
      }

      position: relative;
      display: flex;
      justify-content: center;
      z-index: 1002;

      .disclaimer-txt {
        width: 1403px;
        font-family: NotoSansCJKjp;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #fff;
        padding-top: 24px;
        padding-bottom: 48px;

        span {
          color: red;
        }

      }

      .coin-container {
        @media screen and (max-width: 1714px) {
          display: none;
        }

        .coins-img {
          position: absolute;
          z-index: 1002;
          bottom: 0;
          padding-right: 160px;
        }

      }
    }

    .disclaimer-mobile {
      display: none;

      @media only screen and (min-width: 375px) and (max-width:1024px) {
        display: block;
        padding-top: 16px;
        padding-bottom: 30px;

        .flex-container {
          display: flex;
          justify-content: center;

          .disclaimer-txt {
            width: 343px;
            font-family: NotoSansCJKjp;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #fff;


            span {
              color: red;
            }
          }

        }


      }



    }

    .disclaimer-tablet {
      display: none;

      @media only screen and (min-width: 1025px) and (max-width:1600px) {
        display: block;

        .flex-container {
          display: flex;
          justify-content: center;
          padding-top: 20px;
          padding-bottom: 20px;

          .disclaimer-txt {
            font-family: NotoSansCJKjp;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #fff;


            span {
              color: red;
            }
          }

        }
      }



    }
  }

}
</style>
