import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/jp/HomeView.vue";
import HeaderLocationCheck from "../views/jp/HeaderLocationCheck.vue";
import HomeViewKR from "../views/kr/HomeViewKR.vue";

const routes = [
  {
    path: "/",
    name: "HeaderLocationCheck",
    component: HeaderLocationCheck,
  },
  {
    path: "/jp/landingpage/investment",
    name: "homejp",
    component: HomeView,
    meta: {
      title: "Dooprime フォロートレーディング自動売買 ",
      metaTags: [
        {
          name: "description",
          content:
            "グローバル最高の専門家の取引をdooprimeで無料にフォロートレーディング自動売買し、すべての投資家に安定的な収益率を提供します。 ",
        },
        {
          name: "keywords",
          content:
            "フォロートレーディング、fxマージン取引、外国為替取引、自動売買、財テク、収益率、外国為替投資、自動取引、自動売買、フォロートレーディング、dooprime、 ",
        },
      ],
    },
  },
  {
    path: "/kr/landingpage/investment",
    name: "homekr",
    component: HomeViewKR,
    meta: {
      title: "Doo Prime 카피트레이딩",
      metaTags: [
        {
          name: "description",
          content:
            "DooPrime 은 FX마진거래/해외선물/암호화폐/골드/해외주식 등 1만여개의 금융상품이 거래되고 있는 종합 금융 거래 온라인 브로커입니다. 회원가입후 무료로 지원하는 카피트레이딩 시스템을 통해 글로벌 랭킹 전문가들의 거래를 그대로 카피하세요.",
        },
        {
          name: "keywords",
          content:
            "dooprime,두프라임,fx마진.카피트레이딩,자동매매,외환거래,ea자동,자동거래,카피매매,외환투자,골드투자,재테크,fx투자,수익율",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
