import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import { plugin, defaultConfig } from "@formkit/vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { Carousel, Collapse } from "ant-design-vue";
import VueCarousel from "vue-carousel";
import 'aos/dist/aos.css';
import AOS from 'aos'
import Vue from 'vue'


createApp(App)
  .use(AOS)
  .use(router)
  .use(plugin, defaultConfig)
  .use(Antd)
  .use(VueAxios, axios)
  .use(Carousel)
  .use(Collapse)
  .use(VueCarousel)
  .mount("#app");
