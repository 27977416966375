<template>
    <section class="graph-section">
        <div data-aos="fade-up" class="graph-header">
            <img src="@/assets/kr/sec3_itm_01.png" />
            <img src="@/assets/kr/sec3_itm_02.png" />
        </div>
        <div class="graph-header-mobile">
            <img src="@/assets/kr/sec3_m_01.png" />
            <img src="@/assets/kr/sec3_m_02.png" />
        </div>
    </section>
</template>


<style lang="less">
.graph-section {
    background-image: url(@/assets/kr/sec3_bg.jpg);
    background-position: center center;
    background-size: cover;
    // background-attachment: fixed;

    .graph-header-mobile {
        display: none;
    }

    .graph-header {
        width: 100%;
        height: 100%;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }
}

@media screen and (max-width:781px) {
    .graph-header {
        display: none;
    }

    .graph-section {
        background-image: url(@/assets/kr/sec3_bg_m.jpg) !important;
        background-position: center center;
        background-size: cover;

        .graph-header-mobile {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;

            img {
                width: 100%;
            }
        }
    }


}
</style>