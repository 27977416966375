<template>
    <section class="banner-section">
        <div class="nav ">
            <a href="https://www.dooprime.kr" class="home-btn">
                <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/06.svg"
                    alt="dooprime logo">
            </a>
            <a href="https://www.dooprime.kr" class="home-btn-mobile">
                <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/Images/DP%20Logo%20-%20White%20Text.png"
                    alt="dooprime logo">
            </a>
            <button class="register-btn">
                <a href="https://user.dooprimelinks.com/signup/index?hl=ko">
                    회원가입
                </a>
            </button>
        </div>
        <div class="banner-header">
            <img src="@/assets/kr/sec1_itm_01.png" />
        </div>
        <div class="banner-header-mobile">
            <img src="@/assets/kr/sec1_itm_01_m.png" />
        </div>
    </section>
</template>


<script>

export default {

    methods: {
        stickyNavBar() {
            window.onscroll = () => {
                let navStyle = document.querySelector(".nav");
                    if (window.scrollY > 5) {
                        navStyle.classList.add("sticky")
                    } else {
                        navStyle.classList.remove("sticky");
                }
            }
        },
    },
    mounted() {
        document.body.addEventListener('scroll', this.stickyNavBar())
    },
}




</script>

<style lang="less">
.banner-section {
    background-image: url(@/assets/kr/sec1_bg.jpg);
    background-position: center center;
    background-size: cover;
    // background-attachment: fixed;
    .banner-header-mobile {
        display: none;
    }

    .sticky {
        z-index: 99999;
        position: fixed !important;
        top: 0 !important;
        width: 100% !important;
        background-color: rgba(0, 0, 0, 0.7);
        padding-bottom: 20px !important;
        animation: fadeIn 0.5s;
        -webkit-animation: fadeIn 0.5s;
        -moz-animation: fadeIn 0.5s;
        -o-animation: fadeIn 0.5s;
        -ms-animation: fadeIn 0.5s;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .nav {


        display: flex;
        justify-content: space-between;
        padding-top: 40px;

        @media only screen and (min-width: 375px) and (max-width:1024px) {
            padding-top: 22px;
            justify-content: space-around;
        }

        .home-btn {
            padding-left: 64px;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: none;
            }
        }

        .home-btn-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                padding-left: 0px;
                display: block;
            }
        }

        .register-btn {
            margin-right: 64px;
            background-color: transparent;
            width: 124px;
            height: 48px;
            border-radius: 24px;
            border: solid 1px #fff;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                margin-right: 0px;
                width: 96px;
                height: 36px;
                border-radius: 18px;
                border: solid 1px #fff;
            }

            a {
                text-decoration: none;
                color: #fff;


            }
        }


    }

    .banner-header {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        img {
            width: 100%;
        }
    }
}
@media screen and (max-width:781px) {
    .banner-header {
        display: none;
    }
    .banner-section {
        background-image: url(@/assets/kr/sec1_bg_m.jpg) !important;
        background-position: center center;
        background-size: cover;
        .banner-header-mobile {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
    }
}
</style>
