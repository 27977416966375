<template>
    <section class="header">
        <div class="container">
            <div class="links">
                <div class="link-1">
                    <a href="mailto:jp.support@dooprime.com"><img
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Icon/YouTube.svg"
                            alt="mail-icon">
                        <span>jp.support@dooprime.com</span> </a>
                </div>
                <div class="link-2">
                    <a href="https://profile.ameba.jp/me"><img
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Icon/Ameba.svg"
                            alt="ameba-icon">
                        <span>Ameba</span> </a>
                </div>
                <div class="link-3">
                    <a href="https://line.me/ti/p/OOUFc6vOdN"><img
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Icon/LINE.svg"
                            alt="Line-icon">
                        <span>Line</span> </a>
                </div>

            </div>
            <div class="links-mobile">
                <div class="link-1">
                    <div class="flex-container-1">
                        <a href="mailto:jp.support@dooprime.com"><img
                                src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Icon/YouTube.svg"
                                alt="mail-icon">
                            <span>jp.support@dooprime.com</span> </a>

                    </div>
                </div>
                <div class="flex-container-2">
                    <div class="link-2">
                        <a href="https://profile.ameba.jp/me"><img
                                src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Icon/Ameba.svg"
                                alt="ameba-icon">
                            <span>Ameba</span> </a>
                    </div>
                    <div class="link-3">
                        <a href="https://line.me/ti/p/OOUFc6vOdN"><img
                                src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Icon/LINE.svg"
                                alt="Line-icon">
                            <span>Line</span> </a>
                    </div>

                </div>

            </div>
        </div>
    </section>
</template>


<script>

</script>


<style lang="less" scoped>
.header {
    background-color: #172351;


    .container {
        .links {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: none;
            }

            display: flex;
            justify-content: center;
            gap: 48px;
            padding-top: 24px;
            padding-bottom: 24px;

            .link-1 {
                width: 300px;
                height: 49px;
                padding: 9px 24px 8px;
                border-radius: 40px;
                background-color: #0b1330;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    text-decoration: none;

                    span {
                        padding-left: 8px;
                        color: white;
                    }
                }
            }

            .link-2 {
                width: 156px;
                height: 49px;
                padding: 8px 37px 8px 24px;
                border-radius: 40px;
                background-color: #0b1330;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    text-decoration: none;

                    span {
                        padding-left: 8px;
                        color: white;
                    }
                }
            }

            .link-3 {
                width: 156px;
                height: 49px;
                padding: 9px 55px 8px 24px;
                border-radius: 40px;
                background-color: #0b1330;
                background-color: #0b1330;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    text-decoration: none;

                    span {
                        padding-left: 8px;
                        color: white;
                    }
                }
            }
        }

        .links-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: block;

                .link-1 {
                    .flex-container-1 {
                        display: flex;
                        justify-content: center;
                        padding-top: 16px;

                        a {
                            width: 250px;
                            height: 36px;
                            margin: 0 0 8px;
                            padding: 6px 17px 6px 16px;
                            border-radius: 40px;
                            background-color: #0b1330;

                            span {
                                padding-left: 8px;
                                color: white;
                            }
                        }
                    }
                }

                .flex-container-2 {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 16px;

                    .link-2 {
                        width: 117px;
                        height: 36px;
                        margin-right: 16px;
                        padding: 6px 22px 6px 16px;
                        border-radius: 40px;
                        background-color: #0b1330;

                        a {
                            span {
                                padding-left: 8px;
                                color: white;
                            }
                        }

                    }

                    .link-3 {
                        width: 117px;
                        height: 36px;
                        padding: 6px 22px 6px 16px;
                        border-radius: 40px;
                        background-color: #0b1330;

                        a {
                            span {
                                padding-left: 8px;
                                color: white;
                            }
                        }
                    }
                }

            }
        }
    }
}
</style>