<template>

    <section class="header">
        <div class="container">
            <div class="disclaimer">
                <img src="@/assets/kr/Disclaimer/DisclaimerKR.jpg" alt="Disclaimer">
            </div>
            <div class="disclaimer-mobile">
                <img src="@/assets/kr/Disclaimer/Disclaimer-mobile-KR.jpg" alt="Disclaimer">
            </div>
        </div>
    </section>

</template>


<script></script>

<style lang="less" scoped>
.header {
    background-color: #191919;

    h1 {
        margin-bottom: 0;
    }

    .container {
        .disclaimer {
            @media only screen and (min-width: 375px) and (max-width:780px) {
                display: none;

            }

            img {
                width: 100%
            }

        }

        .disclaimer-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:780px) {
                display: block;

                img {
                    width: 100%
                }
            }
        }
    }
}
</style>