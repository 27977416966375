<template>
    <div class="carousel-awards">
        <a-carousel :after-change="onChange">
            <div class="item-1">
                <!-- <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Gallery%201.png"
          /> -->
                <h3><img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Gallery%201.png"
                        alt="dwadaw"></h3>

            </div>
            <div>
                <div class="item-1">
                    <h3><img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Gallery%202.png"
                            alt="dwadaw"></h3>
                </div>
            </div>
            <div>
                <div class="item-1">
                    <h3><img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Gallery%203.png"
                            alt="dwadaw"></h3>
                </div>
            </div>
            <div>
                <div class="item-1">
                    <h3><img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Gallery%204.png"
                            alt="dwadaw"></h3>
                </div>
            </div>
            <div>
                <div class="item-1">
                    <h3><img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Gallery%205.png"
                            alt="dwadaw"></h3>
                </div>
            </div>
            <div>
                <div class="item-1">
                    <h3><img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/Images/Gallery%206.png"
                            alt="dwadaw"></h3>
                </div>
            </div>
        </a-carousel>
    </div>
</template>
  
<script>
import "ant-design-vue/dist/antd.css";

export default {
    name: "AwardsCarousel",

    setup() {
        const onChange = (current) => {
            console.log(current);
        };

        return {
            onChange,
        };
    },
};
</script>
  
  
<style lang="less">
/* For demo */


.slick-dots li.slick-active button {
    width: 12px !important;
    height: 12px !important;
    border-radius: 100% !important;
    background:  none !important;
    border: solid 1px #d91d22 !important;

}


.ant-carousel :deep(.slick-slide) {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
    color: #fff;
}

.ant-carousel {
    .slick-slider {
        .slick-dots {
            position: static;
            bottom: 12px;
            right: 0;
            left: 0;
            z-index: 15;
            display: flex;
            justify-content: center;
            margin-right: 15%;
            margin-left: 15%;
            padding-left: 0;
            list-style: none;

            li {
                button {
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    border: grey;
                    background-color: grey;
                }
                
            }

            
        }
    }
}

.carousel-awards {
    margin: auto;
    margin-bottom: 72px;

    @media only screen and (min-width: 1025px) and (max-width:1600px) {
        margin-bottom: 0px;
    }

    .item-1 {
        img {
            display: inline;
            width: 344px;
            height: 207px;

            @media only screen and (min-width: 1025px) and (max-width:1600px) {
                width: 350px;
                height: 260px;
            }
        }

        h3 {
            font-family: Roboto;
            font-size: 20px;
            font-weight: bold;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: 1.88px;
            text-align: center;
            color: black;
        }

        p {
            font-family: Roboto;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            text-align: center;
            color: #fff;
            margin: 0 auto;
            margin-top: 40px;
            margin-bottom: 21px;
            width: 262px;
        }
    }
}
</style>
  