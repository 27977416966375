<template>
    <section class="header">
        <div class="container">
            <div class="doo-prime-header">
                <div class="line-1">
                </div>
                <div class="doo-prime-text">
                    <p>
                        Doo Prime 顧客感謝イベント
                    </p>
                </div>
                <div class="line-2">
                </div>
            </div>

            <div class="hero-banner-mobile">
                <div class="doo-prime-img">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/Images/Content%20-%20DP.png"
                        alt="dooprime-logo">
                </div>
                <div class="coin-text-img">
                    <div class="main-text">
                        <p class="text-1">で破格的 </p>
                        <p class="text-2">な恩恵を享受</p>
                        <p class="text-3">してください。</p>
                    </div>
                </div>
            </div>

            <div class="hero-banner">

                <div class="coin-left">

                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Coin%20Left.png"
                        alt="coins">

                </div>

                <div class="main-text">

                    <div class="doo-prime-logo">

                        <img style="width:398px; height:120px;"
                            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Content%20-%20DP.png"
                            alt="dooprime-logo">

                        <p class="text-1">で破格的 </p>

                    </div>

                    <p class="text-2">な恩恵を享受してください。</p>

                </div>

                <div class="coin-right">

                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Coin%20Right.png"
                        alt="coins">

                </div>

            </div>

            <div class="box-img">
                <div class="img-container">
                    <div class="img-left">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Content%203.png"
                            alt="calander-img">
                    </div>
                    <div class="img-right">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Content%204.png"
                            alt="calander-img">
                    </div>
                </div>
            </div>
            <div class="box-img-mobile">
                <div class="img-container">
                    <div class="img-left">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/Images/Content%203.png"
                            alt="calander-img">
                    </div>
                    <div class="img-right">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/Images/Content%204.png"
                            alt="calander-img">
                    </div>
                </div>
            </div>

            <div class="footer-txt">
                <p class="line-1">
                    手数料の払い戻しを受け、お気軽な取引を体験してください
                </p>
                <p class="line-2">
                    限定期間にロールオーバー手数料 <span>全額免除</span> により制限のない注文を実現してください。
                </p>
            </div>
            <div class="footer-txt-mobile">
                <div class="txt-container">
                    <p class="line-1">
                        手数料の払い戻しを受け、 <br>
                        お気軽な取引を体験してください
                    </p>


                </div>
                <div class="txt-container">
                    <p class="line-2">
                        限定期間にロールオーバー
                    </p>
                </div>
                <div class="txt-container">
                    <p class="line-3">
                        手数料<span>全額免除</span> により制限の
                    </p>
                </div>
                <div class="txt-container">
                    <p class="line-4">
                        ない注文を実現してください。
                    </p>
                </div>
            </div>

        </div>
    </section>
</template>

<script>

</script>

<style lang="less" scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.header {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans JP';
    min-width: auto;
    width: 100%;
    background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/BG/BG%203%20-%20No%20Coin.png) no-repeat center top;
    background-size: cover;
    position: relative;

    @media only screen and (min-width: 375px) and (max-width:1024px) {
        background: url(http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/BG/BG%203.png) no-repeat center top;

    }

    .container {
        .doo-prime-header {
            display: flex;
            justify-content: center;
            padding-top: 96px;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                padding-top: 48px;
            }

            .line-1 {
                width: 276px;
                height: 2px;
                margin: 23px 40px 66px 208px;
                opacity: 0.6;
                background-color: #fff;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    width: 63px;
                    height: 1px;
                    margin: 10px 1px 49px 8px;
                    opacity: 0.6;
                    background-color: #fff;
                }

            }

            .doo-prime-text {
                font-family: NotoSansCJKjp;
                font-size: 30px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: center;
                color: #fff;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    font-family: NotoSansCJKjp;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                }
            }

            .line-2 {
                width: 276px;
                height: 2px;
                margin: 23px 207px 66px 40px;
                opacity: 0.6;
                background-color: #fff;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    width: 62px;
                    height: 1px;
                    margin: 10px 0 49px 1px;
                    opacity: 0.6;
                    background-color: #fff;
                }
            }
        }

        .hero-banner-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: block;

                .doo-prime-img {
                    display: flex;
                    justify-content: center;

                    img {
                        width: 196px;
                        height: 65px;

                    }
                }

                .coin-text-img {
                    display: flex;
                    justify-content: center;

                    .text-1,
                    .text-2,
                    .text-3 {
                        font-family: NotoSansCJKjp;
                        font-size: 28px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                        margin: 0;
                    }
                }
            }
        }

        .hero-banner {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: none;
            }

            display: flex;
            justify-content: center;

            .coin-left {
                padding-right: 98px;
            }

            .main-text {
                .doo-prime-logo {
                    display: flex;
                    justify-content: center;

                    img {
                        @media only screen and (min-width: 1025px) and (max-width:1600px) {
                            width: 360px;
                        }
                    }

                    p {
                        font-family: NotoSansCJKkr;
                        font-size: 60px;
                        font-weight: 900;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                    }
                }

                .text-2 {
                    font-family: NotoSansCJKkr;
                    font-size: 60px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                }


            }
        }

        .box-img-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: block;
                padding-top: 32px;

                .img-container {
                    display: flex;
                    justify-content: center;

                    img {
                        width: 168px;
                        height: 143px;
                    }
                }
            }


        }

        .box-img {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: none;
            }

            .img-container {
                display: flex;
                justify-content: center;
            }
        }

        .footer-txt-mobile {
            display: none;

            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: block;

                .txt-container {
                    display: flex;
                    justify-content: center;

                    .line-1 {
                        width: 252px;
                        font-family: NotoSansCJKjp;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.43;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                        padding-bottom: 7px;
                        margin-bottom: 0;


                    }

                    .line-2 {
                        font-family: NotoSansCJKjp;
                        font-size: 18px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.56;
                        letter-spacing: normal;
                        color: #fff;
                        padding-bottom: 7px;
                        margin-bottom: 0;
                    }

                    .line-3 {
                        font-family: NotoSansCJKjp;
                        font-size: 18px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.56;
                        letter-spacing: normal;
                        color: #fff;
                        margin-bottom: 0;

                        span {
                            background-image: linear-gradient(to bottom, #ffdb00 23%, #ffa23c 122%);
                            font-family: NotoSansCJKjp;
                            font-size: 22px;
                            font-weight: 900;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.45;
                            letter-spacing: normal;
                            text-align: center;
                            -webkit-background-clip: text;
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }

                    .line-4 {
                        font-family: NotoSansCJKjp;
                        font-size: 18px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.56;
                        letter-spacing: normal;
                        color: #fff;
                        margin-bottom: 0;
                        padding-bottom: 48px;
                    }



                }



            }
        }

        .footer-txt {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: none;
            }

            padding-bottom: 97px;

            .line-1 {
                font-family: NotoSansCJKjp;
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 8px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
            }

            .line-2 {
                font-family: NotoSansCJKjp;
                font-size: 36px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;

                span {
                    font-family: NotoSansCJKjp;
                    font-size: 44px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-image: linear-gradient(to bottom, #ffdb00 23%, #ffa23c 122%);
                }
            }
        }
    }
}
</style>
