<template>
    <div>
        <Hero></Hero>
        <ChartView></ChartView>
        <DooPrime></DooPrime>
        <TrustedCompany></TrustedCompany>
        <RecommendPartner></RecommendPartner>
        <PotentialDooPrime></PotentialDooPrime>
        <SocialLinks></SocialLinks>
        <DisclaimerBottom></DisclaimerBottom>
        <FixedFooter></FixedFooter>
        <FooterBackground></FooterBackground>
    </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue';
import ChartView from '@/components/ChartView.vue'
import DooPrime from '@/components/DooPrime.vue'
import RecommendPartner from '@/components/RecommendPartner.vue'
import TrustedCompany from '@/components/TrustedCompany.vue'
import PotentialDooPrime from '@/components/PotentialDooPrime.vue'
import FixedFooter from '@/components/FixedFooter.vue';
import SocialLinks from '@/components/SocialLinks.vue';
import DisclaimerBottom from '@/components/DisclaimerBottom.vue';
import FooterBackground from '@/components/FooterBackground.vue';

export default {
    name: 'HomeView',
    components: {
    Hero,
    ChartView,
    DooPrime,
    RecommendPartner,
    TrustedCompany,
    PotentialDooPrime,
    FixedFooter,
    SocialLinks,
    DisclaimerBottom,
    FooterBackground
}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap');

p, h1,h2,h3 {
    font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ","Meiryo","Osaka","MS >Ｐゴシック","MS PGothic", "Open Sans", Arial, sans-serif !important ;
}
body > div {
  overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
}
</style>
