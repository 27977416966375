<template>
    <div class="carousel-awards">
        <a-carousel class="a-carousel" :class="displayLicenseBackground ? 'licenseView' : ''" :after-change="onChange">


            <button @click="displayLicenseSCFSA($event)" class="item-1">

                <img class="imageLicense "
                    src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/License/Licenses/SC%20FSA.png"
                    alt="SCFSA">

            </button>

            <button @click="displayLicenseUKFC($event)" class="item-1">

                <h3>
                    <img class="imageLicense "
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/License/Licenses/UK%20FCA.png"
                        alt="UKFCA">
                </h3>

            </button>
            <button @click="displayLicenseVUFSC($event)" class="item-1">

                <h3>
                    <img class="imageLicense "
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/License/Licenses/VU%20FSC.png"
                        alt="VUFSC">
                </h3>

            </button>
            <button @click="displayLicenseMUFSC($event)" class="item-1">

                <h3>
                    <img class="imageLicense "
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/Japanese-Inv/Mobile/License/Licenses/MU%20FSC.png"
                        alt="MUFSC">
                </h3>

            </button>

        </a-carousel>
        <div class="testtesttest" v-if="displayFSA">
            <div class="containers">
                <img class="imageCertificateLicense"
                    src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/License/SC%20FSA.png"
                    alt="SCFSA">
                <button class="close-btn-popup" @click="closeDisplay">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Close%20Icon.png"
                        alt="close-btn">
                </button>
            </div>
        </div>
        <div class="testtesttest" v-if="displayUKFC">
            <div class="containers">
                <img class="imageCertificateLicense"
                    src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/License/UK%20FCA.png"
                    alt="UKFCA">
                <button class="close-btn-popup" @click="closeDisplay">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Close%20Icon.png"
                        alt="close-btn">
                </button>
            </div>
        </div>
        <div class="testtesttest" v-if="displayVUFSC">
            <div class="containers">
                <img class="imageCertificateLicense"
                    src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/License/VU%20FSC.jpg"
                    alt="VUFSC">
                <button class="close-btn-popup" @click="closeDisplay">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Close%20Icon.png"
                        alt="close-btn">
                </button>
            </div>
        </div>
        <div class="testtesttest" v-if="displayMUFSC">
            <div class="containers">
                <img class="imageCertificateLicense"
                    src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Mobile/License/MU%20FSC.jpg"
                    alt="MUFSC">
                <button class="close-btn-popup" @click="closeDisplay">
                    <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/JP-Landing-Page/New-Assets/Desktop/Images/Close%20Icon.png"
                        alt="close-btn">
                </button>
            </div>
        </div>
    </div>

</template>
  
<script>
import "ant-design-vue/dist/antd.css";
import LicenseSCFSAMobile from "../LicenseView.vue/LicenseSCFSAMobile.vue";
import LicenseUKFCAMobile from "../LicenseView.vue/LicenseUKFCAMobile.vue";
// import MyModal from './Modal.vue'


export default {
    name: "AwardsCarousel",

    components: {
        LicenseSCFSAMobile,
        LicenseUKFCAMobile,
        // MyModal
    },
    setup() {
        const onChange = (current) => {
            console.log(current);
        };

        return {
            onChange,
        };
    },

    data() {
        return {
            displayFSA: false,
            displayLicenseBackground: false,
            displayUKFC: false,
            displayVUFSC: false,
            displayMUFSC: false

        }
    },

    computed: {
        // displayDarkBackground() {
        //     console.log('I am called')
        //     return this.
        // }

    },
    methods: {
        displayLicenseSCFSA(e) {
            this.displayFSA = !this.displayFSA
            // this.displayUKFC = !this.displayUKFC
            // this.displayFSA === true ? e.target.classList.add('active') : e.target.classList.remove('active')
            // this.displayLicenseBackground = !this.displayLicenseBackground
            // this.$refs.childComponent.showModal();
        },
        displayLicenseUKFC(e) {
            this.displayUKFC = !this.displayUKFC

        },
        displayLicenseVUFSC(e) {
            this.displayVUFSC = !this.displayVUFSC

        },
        displayLicenseMUFSC(e) {
            this.displayMUFSC = !this.displayMUFSC

        },

        closeDisplay() {
            this.displayFSA = false,
                this.displayUKFC = false,
                this.displayVUFSC = false,
                this.displayMUFSC = false,
                this.displayLicenseBackground = false
            this.isDisabled = false
        },
        test() {
            console.log('I am called')
            return this.displayLicenseBackground ? 'licenseView' : ''
        }
    }
};
</script>
  
  
<style lang="less">
/* For demo */
.slick-dots li.slick-active button {
    width: 12px !important;
    height: 12px !important;
    border-radius: 100% !important;
    background: none !important;
    border: solid 1px #d91d22 !important;

}


.ant-carousel :deep(.slick-slide) {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
    color: #fff;
}

.ant-carousel {
    .slick-slider {
        .slick-dots {
            position: static;
            bottom: 12px;
            right: 0;
            left: 0;
            z-index: 15;
            display: flex !important;
            justify-content: center;
            margin-right: 15%;
            margin-left: 15%;
            padding-left: 0;
            list-style: none;
            padding-top: 24px;
            margin-bottom: 0;
            padding-bottom: 48px;

            li {
                button {
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    border: grey;
                    background-color: grey;
                }

            }
        }
    }
}



.carousel-awards {

    .testtesttest {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: rgba(0, 0, 0, 0.5);

        .containers {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;

            .imageCertificateLicense {
                display: block;
                width: 342px;
                height: 484px;
                margin-bottom: 32px;
            }

            .close-btn-popup {
                background-color: transparent;
                border: none;

                img {
                    height: 32px;
                    width: 32px;
                }


            }
        }




    }

    .a-carousel {}

    .item-1 {
        background-color: transparent;
        border: none;

        .imageLicense {
            display: inline;
            width: 324px;
            height: 260px;
        }

    }

    .popup-img-container {
        position: fixed;
        top: 0;
        left: 3px;
        z-index: 9999;

        .btn-container {
            display: flex;
            justify-content: center;
            // padding-left: 650px;


            .popup-btn {
                width: 32px;
                height: 32px;
                background-color: transparent;
                border: none;
                color: red;
                position: absolute;
                z-index: 100;
                top: 79px;
                // opacity: 0.5;
                // background-color: #000;

                img {
                    width: 32px;
                    height: 32px;

                }
            }

        }
    }
}
</style>
  