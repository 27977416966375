<template>

    <section class="header">
        <div class="container">
            <div class="disclaimer">
                <h1>
                    このwebサイトは Doo Prime Vanuatu Limited が所有して運営します (Reg. No. 700238) © 2022 Doo Prime. 版権所要.
                </h1>
            </div>
        </div>
    </section>

</template>


<script></script>

<style lang="less" scoped>
.header {
    background-color: #191919;
    h1{
        margin-bottom: 0;
    }

    .container {
        .disclaimer {
            @media only screen and (min-width: 375px) and (max-width:1024px) {
                display: flex;
                justify-content: center;
                padding: 16px 0;

            }
            h1 {
                font-family: NotoSansCJKjp;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 3;
                letter-spacing: normal;
                text-align: center;
                color: #fff;

                @media only screen and (min-width: 375px) and (max-width:1024px) {
                    font-family: NotoSansCJKjp;
                    font-size: 12px;
                    width: 341px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                }
            }
        }
    }
}
</style>